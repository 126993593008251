import React from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"

const Agreement = ({ location }) => {
  return (
    <Layout lang="zh-CN">
      <Seo
        pagetitle="使用条款和条件"
        pagepath={location.pathname}
        pagedesc="使用条款和条件 | PARKLoT提供了一个Twitter活动工具，可以 随心所欲 地运行，只需支付 月费。"
      />
      <Breadcrumb title="使用条款和条件" />
      <main className="policy">
        <section className="policy__section l-container">
          <h1 className="policy__title">使用条款和条件</h1>
          <p className="policy__text">
            本使用条款（以下简称
            "条款"）规定了接受X-HACK公司运营的PARKLoT、PARKLoT
            Lite、优惠券、QR码抽奖和漫画广告（以下简称
            "服务"）所必需的基本事项。
            使用条款规定了提供服务所需的基本事项，并应适用于服务的所有用户。
            所有注册用户（以下简称 "用户"）均受本条款的约束。
            注册用户（以下简称'用户'）被要求按照这些条款和条件使用服务。
          </p>
          <h2 className="policy__title2">第1条（适用）</h2>
          <ol className="policy__olist">
            <li className="policy__item">
              本条款和条件应适用于用户与公司之间有关使用服务的所有关系。
            </li>
            <li className="policy__item">
              除了这些条款和条件之外，公司还可以制定有关服务的各种规定，如使用规则（以下简称
              "个别规定"）。
              这些单独的条款，无论其名称如何，都应构成本条款和条件的一部分。
            </li>
          </ol>
          <h2 className="policy__title2">第2条（使用登记）</h2>
          <ol className="policy__olist">
            <li className="policy__item">
              当潜在的注册者同意条款和条件，按照公司规定的方式申请注册使用，并且公司批准申请时，服务的注册使用即告完成。
            </li>
            <li className="policy__item">
              如果公司认为使用登记的申请人有以下原因，公司可以不批准使用登记的申请，并且没有义务披露任何原因。
              <ul className="policy__list">
                <li className="policy__item">
                  如果在申请用户注册时报告虚假信息。
                </li>
                <li className="policy__item">
                  如果申请是由以前违反这些条款和条件的人提出的
                </li>
                <li className="policy__item">
                  其他本公司认为使用登记不合适的情况
                </li>
              </ul>
            </li>
          </ol>
          <h2 className="policy__title2">第3条（用户ID和密码的管理）</h2>
          <ol className="policy__olist">
            <li className="policy__item">
              用户应妥善管理其服务的用户ID和密码，风险自负。
            </li>
            <li className="policy__item">
              在任何情况下，用户不得将用户ID和密码转让或出借给第三方，或与第三方分享。如果用户ID和密码的组合与注册的信息相吻合，并且用户登录，我们认为该使用是由注册该用户ID的用户自己进行的。
            </li>
            <li className="policy__item">
              本公司对第三方使用用户ID和密码造成的任何损失不承担任何责任，但本公司故意或重大过失的情况除外。
            </li>
          </ol>
          <h2 className="policy__title2">第四条（费用和支付方式）</h2>
          <ol className="policy__olist">
            <li className="policy__item">
              用户应按公司规定的方法支付服务的付费部分，公司应单独确定费用并在网站上显示。
            </li>
            <li className="policy__item">
              如果用户拖欠费用，用户应按14.6%的年率支付滞纳金。
            </li>
            <li className="policy__item">
              如果用户在使用期间或因任何其他原因取消，本公司没有义务退还用户所支付的使用费。
            </li>
          </ol>
          <h2 className="policy__title2">第5条（禁止的事项）</h2>
          <ol className="policy__olist">
            <li className="policy__item">
              禁止违反法律或公共秩序和道德的行为。
            </li>
            <li className="policy__item">禁止采取与刑事犯罪有关的行动。</li>
            <li className="policy__item">禁止在国外合法但在国内非法的行为。</li>
            <li className="policy__item">
              禁止任何侵犯服务中包含的版权、商标和其他知识产权的行为，包括服务的内容。
            </li>
            <li className="policy__item">
              禁止任何扰乱或干扰本公司、其他用户或其他第三方的服务器或网络运作的行为。
            </li>
            <li className="policy__item">
              禁止将通过本服务获得的信息用于商业用途。
            </li>
            <li className="policy__item">
              禁止干扰公司业务或服务运作的行为，如给服务器和网络系统带来过多的负担。
            </li>
            <li className="policy__item">
              禁止未经授权的访问或试图获得这种访问。
            </li>
            <li className="policy__item">
              禁止收集或积累关于其他用户的个人或其他信息。
            </li>
            <li className="policy__item">
              禁止为任何未经授权的目的使用本服务。
            </li>
            <li className="policy__item">
              禁止对服务的其他用户或其他第三方造成不利、损害或不适的行为。
            </li>
            <li className="policy__item">禁止冒充其他用户的行为。</li>
            <li className="policy__item">
              在服务上进行未经公司授权的广告、宣传、招揽或商业活动。
            </li>
            <li className="policy__item">禁止与不相识的异性见面的行为。</li>
            <li className="policy__item">
              禁止直接或间接向反社会力量提供与公司服务有关的利益。
            </li>
            <li className="policy__item">
              禁止转售本服务的功能、资源等的行为。
              这包括网络制作公司、代理机构等使用本服务，代表他们为其他公司操作账户。不允许网络制作公司、公关公司、代理公司等利用多账户功能代表其他公司经营多个不相关的账户，因为这构成了对服务功能和资源的转售。
              一般来说，只有用户自己，或自己的公司，或与自己有资本关系的关联公司等所持有的账户可以使用。
            </li>
            <li className="policy__item">
              原则上，禁止在竞选活动中改变Twitter ID。
            </li>
            <li className="policy__item">
              原则上，在操作运动时，禁止改用锁定账户。
            </li>
            <li className="policy__item">
              如果出现任何属于Twitter禁止范围内的行为，运营商保留限制使用、阻止传输和在适当程度上采取其他措施的权利。
            </li>
            <li className="policy__item">
              违反有关使用链接服务的规则和条例，如Twitter或与服务链接的其他服务（以下简称
              "链接服务"）的使用条款。
            </li>
            <li className="policy__item">
              禁止任何其他被公司认为不合适的行为。
            </li>
            <li className="policy__item">
              如果经营者因用户在每项规定中的行为而遭受损害，经营者可以向用户要求赔偿这种损害。
            </li>
            <li className="policy__item">
              不得张贴含有怪诞、暴力或成人内容的媒体宣传。含有强奸和性侵犯描写的媒体宣传也是不允许的。
            </li>
            <li className="policy__item">
              禁止在服务上向第三方披露的信息中包含虚假信息。
            </li>
          </ol>
          <h2 className="policy__title2">第6条（暂停提供服务，等等）</h2>
          <p className="policy__text">
            如果认为存在以下任何原因，本公司可暂停或中止提供全部或部分服务，而无需事先通知用户。
          </p>
          <ol className="policy__olist">
            <li className="policy__item">
              在对本服务所涉及的计算机系统进行维护或更新时。
            </li>
            <li className="policy__item">
              如果由于不可抗力，如地震、雷电、火灾、停电或自然灾害，导致服务的提供变得困难。
            </li>
            <li className="policy__item">
              在Twitter或其他链接服务出现任何麻烦、中断或暂停提供服务、暂停与本服务的链接、规格改变等情况下。
            </li>
            <li className="policy__item">
              在计算机或电信线路等意外关闭的情况下。
            </li>
            <li className="policy__item">
              在任何其他公司认为难以提供服务的情况下。
            </li>
            <li className="policy__item">
              对于用户或任何第三方因暂停或中断提供服务而遭受的任何不利或损害，无论其原因如何，本公司均不承担任何责任。此外，用户承认并接受，即使公司根据本条规定暂停或中断提供服务，用户也不得要求减少使用费等。
            </li>
          </ol>
          <h2 className="policy__title2">第7条（版权）</h2>
          <ol className="policy__olist">
            <li className="policy__item">
              用户只能使用、张贴或编辑其拥有必要的知识产权（如版权）或已获得必要的权利人授权的文本、图像和视频等信息。
            </li>
            <li className="policy__item">
              用户使用服务发布或编辑的任何文本、图像、视频或其他数据（以下简称
              "发布的数据"）的版权应保留给相关用户或其他现有权利人。但是，本公司可以使用（包括复制、复印、修改、分许可给第三方和任何其他用途）使用本服务张贴或编辑的任何文字、图像、视频等，用户不得对这种使用行使道德权利。
            </li>
            <li className="policy__item">
              除Twitter和前段正文的规定外，服务和与服务有关的所有信息的所有版权和其他知识产权均属于本公司或授权本公司使用的权利人，用户未经许可不得复制、转让、出借、翻译、修改、重印、公开传送（包括使之可传送）、传送、分发、出版、商业性使用、反汇编、反编译、反向工程或其他。
            </li>
          </ol>
          <h2 className="policy__title2">第8条（限制使用和取消登记）</h2>
          <p className="policy__text">
            如果用户属于以下任何一种情况，本公司可以不事先通知，限制用户使用全部或部分服务，或终止用户的注册。
          </p>
          <ol className="policy__olist">
            <li className="policy__item">
              如果这些条款和条件中的任何规定被违反
            </li>
            <li className="policy__item">如果发现登记资料中存在虚假事实</li>
            <li className="policy__item">
              如果您使用或试图使用服务的目的或方式有可能对我们、其他用户或其他第三方造成损害
            </li>
            <li className="policy__item">以任何方式干扰服务的运作</li>
            <li className="policy__item">
              如果他们对自己开出或接受的票据或支票不兑现，或者如果他们被清算所暂停交易或其他类似的行动。
            </li>
            <li className="policy__item">
              如果提出扣押、临时扣押、临时处置、强制执行或拍卖的申请，或收到拖欠税款和公共费用的处置申请。
            </li>
            <li className="policy__item">
              当暂停支付或公司破产，或提出破产、民事恢复、公司重组或特别清算程序的申请。
            </li>
            <li className="policy__item">如果该服务已超过一年未被使用</li>
            <li className="policy__item">
              如果你超过30天没有对我们的询问或其他要求答复的通信作出答复
            </li>
            <li className="policy__item">在属于第2.2条的任何一项的情况下</li>
            <li className="policy__item">
              如果确定在通过本服务发布的活动信息中，有不能被第三方明确识别的信息
            </li>
            <li className="policy__item">
              如果通过本服务发布的活动信息被认为不足以让第三方了解
            </li>
            <li className="policy__item">
              如果公司在其他方面认为使用本服务是不适当的
            </li>
          </ol>
          <p className="policy__text">
            如果出现前款中的任何一项，用户自然会失去对公司所有债务的时间利益，并应立即一次性偿还当时的所有欠款。
          </p>
          <p className="policy__text">
            本公司对因本公司根据本条规定采取的任何行动而给用户造成的任何损失不承担责任。
          </p>
          <h2 className="policy__title2">第11条（服务内容的变更等）</h2>
          <p className="policy__text">
            公司可在不通知用户的情况下改变服务内容或停止提供服务，并对因此给用户造成的任何损失不承担责任。
          </p>
          <h2 className="policy__title2">第12条（使用条款的变更）</h2>
          <p className="policy__text">
            如果本公司认为有必要，可以在任何时候修改这些条款和条件，而不通知用户。如果用户在条款和条件修改后开始使用服务，则视为用户同意修改后的条款和条件。
          </p>
          <h2 className="policy__title2">第13条（个人数据的处理）</h2>
          <p className="policy__text">
            公司、法人实体和个人应按照以下规定妥善处理通过使用服务获得的任何个人数据。
          </p>
          <ol className="policy__olist">
            <li className="policy__item">
              公司应根据公司的
              "隐私政策"，妥善处理通过使用服务获得的任何个人信息。
            </li>
            <li className="policy__item">
              使用本服务的法律实体应按照链接的 "隐私政策
              "妥善处理其通过使用本服务获得的个人信息，该政策描述了注册为会员时的法律实体的明确隐私。
            </li>
            <li className="policy__item">
              使用本服务的个人应根据我们的
              "隐私政策"，妥善处理通过使用本服务获得的任何个人信息。
            </li>
          </ol>
          {/* <h2 className="policy__title2"></h2> */}
          <p className="policy__text">
            用户与公司之间的通知或通信应按公司规定的方式进行。除非用户按照我们指定的单独方法通知我们发生了变化，否则我们将假定当前登记的联系地址是有效的，并向该地址发送通知或联系，该通知或联系将被视为在发送时已到达用户。
          </p>
          <h2 className="policy__title2">第15条（管辖法律和管辖权）</h2>
          <ol className="policy__olist">
            <li className="policy__item">
              这些条款和条件应根据日本的法律进行解释。
            </li>
            <li className="policy__item">
              如果出现与本服务有关的诉讼需求，大阪地方法院将是具有专属管辖权的初审法院。
            </li>
          </ol>
          <h2 className="policy__title2">第16条（翻译）</h2>
          <p className="policy__text">
            日语是这些条款和条件的官方语言，这些条款和条件的其他语言的翻译只是为了方便参考，不具有任何效力。
          </p>
          <br />
          <p className="policy__text">*"QR码 "是Denso Wave公司的注册商标。</p>
          <div className="policy__revision">
            <p className="policy__text">2023年3月2日 修订</p>
            <p className="policy__text">2020年11月18日 修订</p>
            <p className="policy__text">2020年10月26日 修订</p>
            <p className="policy__text">2020年10月1日 成立</p>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default Agreement
